<template lang="pug">
  #simple-wrapper
    Body
</template>

<script>
  export default {
    components: {
      Body: () => import("@/components/layout/Body")
    }
  }
</script>

<style lang="sass" scoped>
  #simple-wrapper
    #body
      width: 100%
</style>
